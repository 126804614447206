import React, { InputHTMLAttributes, useState } from "react";
import clsx from "clsx";
import styles from "./Input.module.scss";
import PasswordShownIcon from "@Admin/assets/opened-eye.svg";
import PasswordHiddenIcon from "@Admin/assets/closed-eye.svg";
import { isEmpty, isNil } from "lodash";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  searchIcon?: boolean;
  disabled?: boolean;
  beforeIcon?: string | JSX.Element;
  error?: boolean | null;
  errorText?: string;
  placeholderOnFocus?: string;
  inputProps?: React.HTMLProps<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
}

const Input = ({
  beforeIcon,
  searchIcon = false,
  type,
  name,
  placeholder,
  placeholderOnFocus,
  value,
  onChange,
  onBlur,
  maxLength = 100,
  error,
  errorText,
  disabled,
  inputProps,
  ...props
}: InputProps) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  return (
    <div
      className={clsx(
        styles.inputWrapper,
        searchIcon && styles.inputWrapperWithSearchIcon
      )}
    >
      {beforeIcon && <div className={styles.beforeIcon}>{beforeIcon}</div>}
      <input
        {...props}
        className={clsx(
          styles.input,
          error && styles.inputError,
          !isEmpty(placeholderOnFocus) && styles.placeholderOnFocus,
          props.readOnly && styles.inputReadOnly
        )}
        type={isPasswordShown ? "text" : type}
        name={name}
        placeholder={
          isEmpty(placeholderOnFocus) ? placeholder : placeholderOnFocus
        }
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={maxLength}
        disabled={disabled}
        readOnly={props.readOnly}
        tabIndex={props?.readOnly ? -1 : 1}
        {...inputProps}
      />
      {placeholder && <label className={styles.label}>{placeholder}</label>}
      {type === "password" && (
        <button
          className={styles.passwordEye}
          onMouseDown={() => setIsPasswordShown(true)}
          onMouseUp={() => setIsPasswordShown(false)}
        >
          {isPasswordShown ? <PasswordShownIcon /> : <PasswordHiddenIcon />}
        </button>
      )}
      {error && errorText && <p className={styles.errorText}>{errorText}</p>}
    </div>
  );
};

export default Input;
