import { Member } from "@Shared/features/members/v2/models";
import _, { isEmpty, isNil } from "lodash";
import { matchIsValidTel } from "mui-tel-input";
import i18n from "@Admin/i18n";

export const validatePhoneNumber = (value: string | undefined, canEmpty: boolean = false) => {
    if (canEmpty && (_.isUndefined(value) || _.isEmpty(value))) {
        return(undefined);
    }

    if (_.isUndefined(value) || _.isEmpty(value))
        return("Обязательное поле");
    if (!matchIsValidTel(value))
        return("Неверный формат");    
    return(undefined);
}

export const validatePersonalNumber = (value: string | undefined, regionId: number | undefined, isAvailable: boolean | undefined) => {
    if (_.isEmpty(value) || _.isUndefined(value)){
        return(i18n.t("form.validationMessages.requiredField"));
    }
    
    if (!personalNumberRegExp.test(value ?? "")){
        return(i18n.t("form.validationMessages.wrongFormat"));
    }

    if (!regionId || !value.endsWith(regionId!.toString())){
        return(i18n.t("form.validationMessages.wrongRegionCode"));
    }

    if (!isAvailable){
        return(i18n.t("form.validationMessages.personalNumberIsTaken"))
    }

    return undefined;
}

export const verifyPassword = (password?: string | null, comparePassword?: string | null) => {
    if (_.isEmpty(password))
        return undefined;
    
    if (!passwordRegExp.test(password ?? ""))
        return (i18n.t("form.validationMessages.invalidPasswordCharacters"));

    if (_.isUndefined(comparePassword))
        return undefined;

    if (password !== comparePassword)
        return (i18n.t("form.validationMessages.passwordsAreNotMatched"));
}

export const validationLength = (value: string, min: number | null, max: number | null) : string | undefined => {
    if (min !== null && min !== 0 && (_.isNil(value) || value.length < min)) {
        return `Минимальное количество символов - ${min}`;
    } 

    if (max !== null && value?.length > max) {
        return `Максимальное количество символов - ${max}`;
    }

    return;
}

export const validationSelected = (value: string, includes: string[]) : string | undefined => {
    if(includes.includes(value) === false){
        return "Не выбрано";
    }

    return;
}

export const validationFormDatePeriodString = (type) => (from: string | null, to: string | null) => {
    try {
        
        const fromDate = from && Number(from);
        const toDate = to && Number(to);

        if(fromDate && fromDate < 1900)
        {
            return i18n.t("form.validationMessages.minYearValue")
        }

        if (fromDate && toDate && fromDate > toDate) {
            if (type === `from`) {
                return i18n.t("form.validationMessages.biggerThanFrom")
            }
            if (type === `to`) {
                return i18n.t("form.validationMessages.smallerThanTo")
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const validationFormDatePeriod = (type, skipUpToToday?: boolean) => (from, to) => {
    try {
        const fromDate = from && new Date(from);
        const toDate = to && new Date(to);
        
        if(!skipUpToToday)
        {
            const todayDate = new Date(_.now());
            if (fromDate > todayDate || toDate > todayDate){
                return `Максимально допустимая дата: ${todayDate.toLocaleDateString("ru-RU")}`
            }
        }

        if (fromDate && toDate && fromDate > toDate) {
            if (type === `from`) {
                return `Максимально допустимая дата: ${toDate.toLocaleDateString("ru-RU")}`
            }
            if (type === `to`) {
                return `Минимально допустимая дата: ${fromDate.toLocaleDateString("ru-RU")}`
            }
        }
    } catch (e) {
        console.log(e);
    }
}

export const validationEmail = (value?: string | null, canEmpty: boolean = false)  => {
    if (canEmpty && (_.isNil(value) || _.isEmpty(value))) {
        return(undefined);
    }

    if (_.isNil(value) || _.isEmpty(value))
        return("Обязательное поле");

    const re = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/gi);
    if (!re.test(value)) {
        return `Введите корректный почтовый адрес`;
    }
   
    return(undefined);
}

export const validateIsEmpty = function(value: string | number | undefined | null, setState: (val: any) => void) : boolean {
    if ((_.isString(value) && _.isEmpty(value)) || (_.isNil(value))) {
        setState("Обязательное поле");
        return false;
    }
    
    setState(undefined);
    return true;
}

export const validateDoB = (value: Date | null | undefined, required: boolean = true) => {
    if (_.isNil(value) && !required) {
        return undefined;
    }
    
    if (_.isNil(value)){
        return (i18n.t("form.validationMessages.requiredField"));
    }

    if (value >= new Date()){
        return (i18n.t("form.validationMessages.wrongFormat"));
    }

    return undefined;
}

export const validateEmail = (value: string, isAvailable: boolean | undefined, canEmpty: boolean = false) => {
    if (canEmpty && (_.isNil(value) || _.isEmpty(value))) {
        return undefined;
    }

    if (_.isNil(value) || _.isEmpty(value)){
        return(i18n.t("form.validationMessages.requiredField"));
    }

    if (!newerEmailRegExp.test(value)) {
        return(i18n.t("form.validationMessages.wrongFormat"));
    }

    if (!isAvailable){
        return(i18n.t("form.validationMessages.emailIsTaken"));
    }

    return undefined;
}

export const getTextFieldValidation = (required: boolean, pattern: RegExp | null, minLength: number | null, maxLength: number | null) => {
    let validation = {};
    if (!isNil(required) && required){
        validation["required"] = {
            value: true,
            message: i18n.t("form.validationMessages.requiredField")
        }
    }

    if (!isNil(pattern)){
        validation["pattern"] = {
            value: pattern,
            message: i18n.t("form.validationMessages.wrongFormat")
        }
    }

    if (!isNil(minLength) && minLength >= 0){
        validation["minLength"] = {
            value: minLength,
            message: i18n.t("form.validationMessages.minLength", {count: minLength})
        }
    }

    if (!isNil(maxLength) && maxLength > 0) {
        validation["maxLength"] = {
            value: maxLength,
            message: i18n.t("form.validationMessages.maxLength", {count: maxLength})
        }
    }

    return validation;
}

export const emailRegExp: RegExp = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/gi;
export const personalNumberRegExp = /^БФТ\s\d\d\d\d-\d$/;
export const fullNamePartsRegExp = /^(?![ -])(([А-я -](?![ -][ -]))+)(?<![ -])$/i;
export const partPhoneMaxLength = 9;
export const newerEmailRegExp: RegExp = /^[a-zA-Z0-9._-]{1,64}@[a-zA-Z0-9.-]{1,250}\.[a-zA-Z]{2,4}$/;
export const passwordRegExp = /^[A-Za-z\d\`\~\!\@\#\$\%\^\&\*\(\)\_\-\+\=\{\}\[\]\\\|\:\;\"\'\<\>\,\.\?\/]*$/;
export const emailMaxLength = 50;
export const clubNameRegExp = /^(?![ -])(([А-я0-9 "'-](?![- ][- ])(?!["']["']))+)(?<![ -])$/i;
export const addressRegExp = /^[А-я ,0-9.]+$/i;
export const cityRegExp = /^[А-я ,0-9.]+$/i;